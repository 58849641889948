import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {TextField, Button, CardMedia} from '@material-ui/core';
import {FormGroup, FormControl} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import classNames from "classnames";
import Media from '../media';
import {styles} from '../../layout/theme';
import language from '../../layout/language';
import ReactLazyCardMedia from '../shares/react_lazy_card';

import Utils from '../../api/api';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ImageControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: ''
    }
  }
  componentDidMount(){
    //console.log(this.props.value);
    this.setState({
      value: this.props.value
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    //console.log(nextProps.value);
    this.setState({
      value: nextProps.value
    });
  }
  selectImg = (value) => {
    var $this = this;
    //console.log(value);
    this.setState({value:value},function(){
      $this.handleImageChange(value);
      $this.handleClose();
    });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleImageChange(value){
    this.setState({value:value});
    this.props.handleChange(value);
  }
  render(){
    const classes = this.props.classes;
    const value = this.state.value;

    var label = this.props.label;
    if(!label) label = language.VN.image;

    return (
      <FormGroup style={{...this.props.style}} row className={classes.formRowInline}>
        <FormControl className={classes.formImageControl}>
          <TextField label={label} className={classes.textField} value={value} onClick={this.handleClickOpen} margin="normal" InputLabelProps={{shrink: true}}/>
          {
            value !=='' && (
              //<CardMedia className={classes.fluidThumbnail} image={Utils.fixImagePath(value)}/>
              <ReactLazyCardMedia className={classNames(classes.fluidThumbnail,this.props.className)} title={value} image={ Utils.fixImagePath(value) } />
            )
          }
        </FormControl>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <div className={classes.dialogMedia}>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={this.handleClose}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary">{language.VN.btnSave}</Button>
            </FormGroup>
            <Media onSelectChange={this.selectImg}/>
          </div>
        </Dialog>
      </FormGroup>
    );
  }
}
ImageControl.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ImageControl);
